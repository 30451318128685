var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.postureQuickAccess
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: _vm.$vuetify.breakpoint.xs },
              model: {
                value: _vm.postureQuickAccess,
                callback: function($$v) {
                  _vm.postureQuickAccess = $$v
                },
                expression: "postureQuickAccess"
              }
            },
            [
              _c("quick-assess", {
                attrs: { type: "webcamAnalysis" },
                model: {
                  value: _vm.postureQuickAccess,
                  callback: function($$v) {
                    _vm.postureQuickAccess = $$v
                  },
                  expression: "postureQuickAccess"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("v-row", { attrs: { justify: "center", "no-gutters": "" } }, [
                _c("img", {
                  staticClass: "data-hj-suppress",
                  style: _vm.poseNetResults.capturedImage
                    ? ""
                    : "max-height: 400px",
                  attrs: {
                    "data-hj-suppress": "",
                    src: _vm.poseNetResults.capturedImage
                      ? _vm.poseNetResults.capturedImage
                      : require("@/assets/images/webcam_placeholder.png"),
                    width: _vm.poseNetResults.capturedImage ? "100%" : ""
                  }
                })
              ])
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", "align-self": "stretch" } },
            [
              _c(
                "v-row",
                {
                  staticStyle: { height: "100%" },
                  attrs: { "align-content": "stretch", "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { cols: "12" } },
                    [
                      _c("WebcamInformationCard", {
                        staticClass: "d-flex align-center",
                        staticStyle: { height: "100%" },
                        attrs: {
                          colour: _vm.screenDistanceColour,
                          imageUrl: "screenDistanceIcon",
                          headerText: _vm.screenDistanceOverviewText,
                          fadedHeader: false,
                          preValueText: _vm.screenDistancePreValueText,
                          valueText: _vm.screenDistanceValueText,
                          postValueText: _vm.screenDistancePostValueText,
                          hideValueText: _vm.screenDistanceColour == "green",
                          showingResults: "",
                          title: _vm.$t(
                            "deskAssessment.results.webcamAssessmentContent.screenDistanceTitle"
                          ),
                          fromReport: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-2", attrs: { cols: "12" } },
                    [
                      _c("WebcamInformationCard", {
                        staticClass: "d-flex align-center",
                        staticStyle: { height: "100%" },
                        attrs: {
                          colour: _vm.screenHeightColour,
                          imageUrl: "screenHeightIcon",
                          headerText: _vm.screenHeightOverviewText,
                          fadedHeader: false,
                          preValueText: _vm.screenHeightPreValueText,
                          valueText: _vm.screenHeightValueText,
                          postValueText: _vm.screenHeightPostValueText,
                          hideValueText: false,
                          showingResults: "",
                          title: _vm.$t(
                            "deskAssessment.results.webcamAssessmentContent.screenHeightTitle"
                          ),
                          fromReport: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.shoulderToEarResultsExist
            ? _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c("WebcamInformationCard", {
                    staticClass: "d-flex align-center",
                    staticStyle: { height: "100%" },
                    attrs: {
                      colour: _vm.shoulderToEarDifferenceColour,
                      imageUrl: "shoulderToEarIcon",
                      headerText: _vm.shoulderToEarDifferenceOverviewText,
                      fadedHeader: "",
                      preValueText: _vm.shoulderToEarDifferencePreValueText,
                      valueText: _vm.shoulderToEarDifferenceValueText,
                      postValueText: _vm.shoulderToEarDifferencePostValueText,
                      supplementaryText:
                        _vm.shoulderToEarDifferenceSupplementaryText,
                      hideValueText: false,
                      showingResults: "",
                      title: _vm.$t(
                        "deskAssessment.results.webcamAssessmentContent.neckPositionTitle"
                      ),
                      fromReport: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              attrs: { cols: "12", lg: _vm.shoulderToEarResultsExist ? 6 : 12 }
            },
            [
              _c("WebcamInformationCard", {
                staticClass: "d-flex align-center",
                staticStyle: { height: "100%" },
                attrs: {
                  colour: _vm.shoulderHeightColour,
                  imageUrl: "shoulderAngleIcon",
                  headerText: _vm.shoulderDistanceOverviewText,
                  fadedHeader: "",
                  preValueText: _vm.shoulderDistancePreValueText,
                  valueText: _vm.shoulderDistanceValueText,
                  postValueText: _vm.shoulderDistancePostValueText,
                  supplementaryText: _vm.shoulderDistanceSupplementaryText,
                  hideValueText: false,
                  showingResults: "",
                  title: _vm.$t(
                    "deskAssessment.results.webcamAssessmentContent.shoulderPositionTitle"
                  ),
                  fromReport: ""
                }
              })
            ],
            1
          ),
          _vm.viewingOwnResults
            ? _c(
                "v-btn",
                {
                  staticClass: "mx-auto my-4",
                  attrs: {
                    id: "quickAssessPosture",
                    color: "secondary",
                    rounded: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.postureQuickAccess = true
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.fixPosture")) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }