<template>
  <div>
    <v-dialog
      v-if="postureQuickAccess"
      v-model="postureQuickAccess"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <quick-assess type="webcamAnalysis" v-model="postureQuickAccess" />
    </v-dialog>
    <v-row>
      <v-col cols="12" lg="6">
        <v-row justify="center" no-gutters>
          <img
            class="data-hj-suppress"
            data-hj-suppress
            :src="
              poseNetResults.capturedImage
                ? poseNetResults.capturedImage
                : require('@/assets/images/webcam_placeholder.png')
            "
            :width="poseNetResults.capturedImage ? '100%' : ''"
            :style="poseNetResults.capturedImage ? '' : 'max-height: 400px'"
          />
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" align-self="stretch">
        <v-row style="height: 100%" align-content="stretch" no-gutters>
          <v-col cols="12" class="mb-2">
            <WebcamInformationCard
              :colour="screenDistanceColour"
              imageUrl="screenDistanceIcon"
              :headerText="screenDistanceOverviewText"
              :fadedHeader="false"
              :preValueText="screenDistancePreValueText"
              :valueText="screenDistanceValueText"
              :postValueText="screenDistancePostValueText"
              :hideValueText="screenDistanceColour == 'green'"
              showingResults
              :title="
                $t(
                  'deskAssessment.results.webcamAssessmentContent.screenDistanceTitle'
                )
              "
              style="height: 100%"
              class="d-flex align-center"
              fromReport
            />
          </v-col>
          <v-col cols="12" class="mt-2">
            <WebcamInformationCard
              :colour="screenHeightColour"
              imageUrl="screenHeightIcon"
              :headerText="screenHeightOverviewText"
              :fadedHeader="false"
              :preValueText="screenHeightPreValueText"
              :valueText="screenHeightValueText"
              :postValueText="screenHeightPostValueText"
              :hideValueText="false"
              showingResults
              :title="
                $t(
                  'deskAssessment.results.webcamAssessmentContent.screenHeightTitle'
                )
              "
              style="height: 100%"
              class="d-flex align-center"
              fromReport
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" v-if="shoulderToEarResultsExist">
        <WebcamInformationCard
          :colour="shoulderToEarDifferenceColour"
          imageUrl="shoulderToEarIcon"
          :headerText="shoulderToEarDifferenceOverviewText"
          fadedHeader
          :preValueText="shoulderToEarDifferencePreValueText"
          :valueText="shoulderToEarDifferenceValueText"
          :postValueText="shoulderToEarDifferencePostValueText"
          :supplementaryText="shoulderToEarDifferenceSupplementaryText"
          :hideValueText="false"
          showingResults
          :title="
            $t(
              'deskAssessment.results.webcamAssessmentContent.neckPositionTitle'
            )
          "
          style="height: 100%"
          class="d-flex align-center"
          fromReport
        />
      </v-col>
      <v-col cols="12" :lg="shoulderToEarResultsExist ? 6 : 12">
        <WebcamInformationCard
          :colour="shoulderHeightColour"
          imageUrl="shoulderAngleIcon"
          :headerText="shoulderDistanceOverviewText"
          fadedHeader
          :preValueText="shoulderDistancePreValueText"
          :valueText="shoulderDistanceValueText"
          :postValueText="shoulderDistancePostValueText"
          :supplementaryText="shoulderDistanceSupplementaryText"
          :hideValueText="false"
          showingResults
          :title="
            $t(
              'deskAssessment.results.webcamAssessmentContent.shoulderPositionTitle'
            )
          "
          style="height: 100%"
          class="d-flex align-center"
          fromReport
        />
      </v-col>
      <v-btn
        v-if="viewingOwnResults"
        class="mx-auto my-4"
        id="quickAssessPosture"
        color="secondary"
        rounded
        @click="postureQuickAccess = true"
      >
        {{ $t("buttons.fixPosture") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WebcamInformationCard from "@/components/common/report/cards/WebcamInformationCard";
import DeskAssessmentText from "@/services/deskassessment/desk-assessment-text";
const QuickAssess = () => import("@/components/dashboard/QuickAssess.vue");
import { viewingOwnResults } from "@/services/privacy-service.js";

export default {
  name: "WebcamAssessmentContent",
  components: {
    WebcamInformationCard,
    QuickAssess
  },
  props: {
    poseNetResults: {}
  },
  data() {
    return { postureQuickAccess: false };
  },
  computed: {
    ...mapGetters(["metricSystem"]),
    screenDistanceOverviewText() {
      return DeskAssessmentText.screenDistanceOverviewText(
        this.poseNetResults,
        true
      );
    },
    screenDistancePreValueText() {
      return DeskAssessmentText.screenDistancePreValueText();
    },
    screenDistanceValueText() {
      return DeskAssessmentText.screenDistanceValueText(
        this.poseNetResults,
        this.metricSystem
      );
    },
    screenDistancePostValueText() {
      return DeskAssessmentText.screenDistancePostValueText(
        this.poseNetResults
      );
    },
    screenHeightOverviewText() {
      return DeskAssessmentText.screenHeightOverviewText(
        this.poseNetResults,
        true
      );
    },
    screenHeightPreValueText() {
      return DeskAssessmentText.screenHeightPreValueText(this.poseNetResults);
    },
    screenHeightValueText() {
      return DeskAssessmentText.screenHeightValueText(this.poseNetResults);
    },
    screenHeightPostValueText() {
      return DeskAssessmentText.screenHeightPostValueText();
    },

    shoulderToEarDifferenceOverviewText() {
      return DeskAssessmentText.shoulderToEarDifferenceOverviewText();
    },
    shoulderToEarDifferencePreValueText() {
      return DeskAssessmentText.shoulderToEarDifferencePreValueText(
        this.poseNetResults
      );
    },
    shoulderToEarDifferenceValueText() {
      return DeskAssessmentText.shoulderToEarDifferenceValueText(
        this.poseNetResults
      );
    },
    shoulderToEarDifferencePostValueText() {
      return DeskAssessmentText.shoulderToEarDifferencePostValueText(
        this.poseNetResults
      );
    },
    shoulderToEarDifferenceSupplementaryText() {
      return DeskAssessmentText.shoulderToEarDifferenceSupplementaryText(
        this.poseNetResults
      );
    },
    shoulderDistanceOverviewText() {
      return DeskAssessmentText.shoulderDistanceOverviewText();
    },
    shoulderDistancePreValueText() {
      return DeskAssessmentText.shoulderDistancePreValueText(
        this.poseNetResults
      );
    },
    shoulderDistanceValueText() {
      return DeskAssessmentText.shoulderDistanceValueText(
        this.poseNetResults,
        false,
        this.metricSystem
      );
    },
    shoulderDistancePostValueText() {
      return DeskAssessmentText.shoulderDistancePostValueText(
        this.poseNetResults
      );
    },
    shoulderDistanceSupplementaryText() {
      return DeskAssessmentText.shoulderDistanceSupplementaryText(
        this.poseNetResults
      );
    },
    screenDistanceColour() {
      return DeskAssessmentText.screenDistanceColour(this.poseNetResults);
    },
    screenHeightColour() {
      return DeskAssessmentText.screenHeightColour(this.poseNetResults);
    },
    shoulderToEarDifferenceColour() {
      return DeskAssessmentText.shoulderToEarDifferenceColour(
        this.poseNetResults
      );
    },
    shoulderHeightColour() {
      return DeskAssessmentText.shoulderHeightColour(this.poseNetResults);
    },
    shoulderToEarResultsExist() {
      return this.poseNetResults.shoulderToEarDifference !== null;
    },
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    }
  }
};
</script>
